import React from 'react'
import styled from 'styled-components'

const InputContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
`
const SingleTextBox = styled(({ className, boxPosition, onRef, ...props }) => {
  return (
    <div className={className}>
      <input className="SingleDigit"
        autoFocus={boxPosition === 0}
        ref={onRef}
        type="text"
        inputMode="numeric"
        pattern="[0-9]"
        autoComplete="one-time-code"
        {...props}
      />
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 0px 0px;
  position: static;
  width: 45px;
  height: 50px;
  left: 2.5px;
  top: 0px;
  background: #FFFFFF;
  // border: 1px solid ${props => props.status === 'failed' ? '#E84A5E' : '#005564'};
  border: 1px solid #373737;
  /* Form Box Shadow */
  box-shadow: 0px 2px 8px rgba(79, 119, 169, 0.12);
  border-radius: 9px;
  .SingleDigit {
    overflow: hidden;
    border-style: hidden;
    text-align: center;
    position: static;
    width: 40px;
    height: 30px;
    left: 10px;
    top: 10px;

    font-family: Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;

    padding-top: 9px;
    :focus::-webkit-input-placeholder {
      opacity: 0;
    }
    ::-webkit-input-placeholder {
      position:relative;
      top: 4px;
    }
    
    /* or 100% */

    color: #3A3A3A;
    &:focus {
      outline: none;
    }
    /*remove arrow up and down */
    &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }
`

const TOKEN_LENGTH = 6

const OTPInput = ({ status = null, validateOTPFn }) => {
  const [inputbox, setInputBox] = React.useState(new Array(TOKEN_LENGTH).fill(1).map((_, idx) => ''))
  const itemsRef = React.useRef([])
  const [sentValue, setSentValue] = React.useState('')

  React.useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, TOKEN_LENGTH);
    // itemsRef.current[0].focus() // try to auto focus on the first box
  }, []);

  const focusBox = (idx) => {
    const x = idx < 0 ? 0 : idx > (TOKEN_LENGTH - 1) ? TOKEN_LENGTH - 1 : idx
    const box = itemsRef.current[x]
    box.focus()
  }

  const onKeyDown = (idx, event) => {
    // handles backspace...
    const { keyCode } = event
    const currValue = inputbox[idx]
    if (keyCode === 39) { // right key
      focusBox(idx + 1)
    } else if (keyCode === 37) { // left key
      focusBox(idx - 1)
    } else if (keyCode === 8) { // backspace
      // backspace key is press only move backward if the field is not empty
      if (currValue.length > 0) {
        setInputBox(pv => {
          pv[idx] = ''
          return [...pv]
        })
      } else {
        focusBox(idx - 1)
      }
    }
  }

  const handleKeychange = (idx, event) => {
    // if value is valid, update state...
    const srcval = event.target.value
    const cleansedValue = srcval.replace(/[^0-9]/g, '').trim()
    if (cleansedValue.length > 0) {
      // user may have pasted a value gt 1 character, apply to additional boxes...
      cleansedValue.split('').forEach((val, cvidx) => {
        const relidx = idx + cvidx
        if (relidx < TOKEN_LENGTH) {
          setInputBox(pv => {
            pv[relidx] = val
            return [...pv]
          })
          const next = itemsRef.current[Math.min(TOKEN_LENGTH - 1, relidx + 1)]
          next.focus()
        }
      })
    }
  }


  React.useEffect(() => {
    const validInput = inputbox.every((x) => x.length > 0)
    const token = inputbox.join('')
    if (validInput && typeof validateOTPFn === 'function' && token !== sentValue) {
      setSentValue(token)
      validateOTPFn(token)
    }
  }, [inputbox, validateOTPFn])


  return (
    <InputContainer>
      {inputbox.map((val, idx) => <SingleTextBox key={idx}
        boxPosition={idx}
        style={{ zIndex: '10' }}
        onRef={el => itemsRef.current[idx] = el}
        value={val}
        onChange={e => handleKeychange(idx, e)}
        onKeyDown={e => onKeyDown(idx, e)}
        onClick={e => focusBox(idx)}
        placeholder="*"
        status={status}
      />)}
    </InputContainer>
  )
}

export default OTPInput