import React from "react"
import Layout from "../components/layout"
import styled from 'styled-components'
import {
  Flex,
  WhiteSection,
  LightBlueSection,
  HeaderBanner,
  GreenBarSection,
  Text,
  Buttons,
  Vis,
  Images,
  TABLET_ONLY_CSS_WRAPPER,
  ButtonLinkOutline,
  ButtonGreen
} from '../components/common'
import { navTo } from '../utils/navigation'
import OTPInput from '../components/OTPInput'
import api from '../utils/api'
import InfoIconSvg from '../images/Info_Dark_18.svg'
import screenListen from '../utils/screenListener'
import GlobalStoreContext from '../components/GlobalContext'
import { useQueryClient } from 'react-query'

const InfoIcon = styled.img`
  height:20px;
  width:20px;
  border-radius:10px;
  margin: auto; 
`

const BodyWrapper = styled(Flex)`
`

const Footer = styled(Flex)`
`

const ErrorBootstrapSection = styled.div`
  margin: 20px 18px;
  ${TABLET_ONLY_CSS_WRAPPER('margin: 20px 16%')}
`

const EnterOTPSection = ({ validatedOTP, verifyToken, resendToken, validateTextMsg }) => {
  return (<>
    <Text.InputFieldHeader style={{ paddingBottom: '10px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Enter one-time password</Text.InputFieldHeader>
    <Vis.NonMobile flex="col">
      <Flex flex="vcenter">
        <OTPInput status={validatedOTP} validateOTPFn={(token) => verifyToken(token)} />
        <Flex style={{ marginLeft: '10px', color: '#373737', fontFamily: 'Arial' }}>{validatedOTP === 'success' ? <Images.CheckCircle /> : validatedOTP === 'failed' ? <Images.ErrorCircle /> : <Images.RoundCircle />}</Flex>
      </Flex>
      <Text.HelperText style={{ paddingTop: '15px', }}><span style={{ color: '#373737', fontFamily: 'Arial' }}>{validateTextMsg}</span><br /><b style={{ cursor: 'pointer', textDecoration: 'underline', color: '#373737', fontFamily: 'Arial' }} onClick={() => resendToken()}>Get a new one</b></Text.HelperText>
    </Vis.NonMobile>
    <Vis.MobileOnly flex="col">
      <OTPInput status={validatedOTP} validateOTPFn={(token) => verifyToken(token)} />
      <Text.HelperText style={{ paddingTop: '10px', }}><span style={{ color: validatedOTP === 'failed' ? '#E84A5E' : '#005564' }}>{validateTextMsg}</span><br /><b style={{ cursor: 'pointer', textDecoration: 'underline', color: '#373737', fontFamily: 'Arial' }} onClick={() => resendToken()}>Get a new one</b></Text.HelperText>
      <Flex flex="center" style={{ height: '70px', minHeight: '70px', margin: '20px 0px' }}>{validatedOTP === 'success' ? <Images.CheckCircle /> : validatedOTP === 'failed' ? <Images.ErrorCircle /> : <Images.EmptyPlaceHolder />}</Flex>
    </Vis.MobileOnly>
  </>)
}

const ButtonDelay = (navigateFn) => {
  setTimeout(() => navigateFn(), 300)
}

const LetsGetYouVerifiedOtpPage = (props) => {

  let { location: { state = {} } = {} } = props
  if (state === null) state = {}
  const { mobile = '[no mobile provided]', isCommercial = false } = state

  const [validatedOTP, setValidatedOTP] = React.useState(null)
  const [validateTextMsg, setValidateTextMsg] = React.useState("Didn't receive your  one-time password?")
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState(null)
  const nextButtonRef = React.useRef(null)

  const { setInSession } = React.useContext(GlobalStoreContext)

  const queryClient = useQueryClient()

  const verifyToken = async (tokens) => {
    setValidatedOTP(null)
    api.verifyOTP(tokens).then(() => {
      console.log("verify success")
      queryClient.invalidateQueries('PRO_MEMBER')
      setInSession(true)
      setValidatedOTP('success')
      //setValidateTextMsg("Didn't receive your one-time password?")
      // console.log("nextButtonRef.current", nextButtonRef.current)
      // nextButtonRef.current.focus()  TODO: nick to help look
    }).catch((err) => {
      const { response } = err
      console.log("err :: " + err)
      if (response?.status === 400) {
        setValidatedOTP('failed')
        setValidateTextMsg("Your one-time password is incorrect. Try again")
      } else if (response?.status === 403) {
        setValidatedOTP('failed')
        // to back to previous screen
        setValidateTextMsg("Invalid session, please click back button to start again.")
      } else {
        // assume server error
        setValidatedOTP('failed')
        setValidateTextMsg("Unable to complete your request, please try again.")
      }

    })
  }

  const createUserAndNavigateNext = () => {
    setErrorMsg(null)
    setIsLoading(true)
    api.createUser().then(() => {
      setIsLoading(false)
      navTo.selectyourbank_5()
    }).catch(() => {
      console.error("Failed to create user")
      setIsLoading(false)
      setErrorMsg("Unable to create user, please try again")
      // TODO : need to display error message
    })
  }

  const resendToken = () => {
    api.resendOTP().then(() => {
      console.debug("resend success")
    }).catch((err) => {
      console.error("Unable to resend one-time password")
      // assume server error
      setValidatedOTP('failed')
      setValidateTextMsg("Unable to complete your request, please try again.")
    })
  }

  const InfoSection = () => {
    return (<>
      <div><InfoIcon src={InfoIconSvg} /></div>
      <Text.HelperText style={{ paddingLeft: '15px' }}>Entering this one-time password verifies that you have access to your phone </Text.HelperText>
    </>)
  }

  const ButtonGroup = () => {
    return (
      <Flex flex="row">
        {
          errorMsg &&
          <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px', marginTop: '-60px' }}>
            <div className="alert alert-danger">{errorMsg}</div>
          </ErrorBootstrapSection>
        }
        <ButtonLinkOutline
          style={{ padding: "0 30px", marginRight: '21px', minWidth: "50%" }}
          rel="noreferrer noopener"
          onClick={() => isCommercial ? ButtonDelay(navTo.commercial) : ButtonDelay(navTo.letsgetyouverified_3)}>
          Back
        </ButtonLinkOutline>
        <ButtonGreen
          style={{ padding: "0 30px", minWidth: "50%" }}
          onClick={() => isCommercial ? ButtonDelay(navTo.dashboard) : createUserAndNavigateNext()}
          disabled={validatedOTP === 'failed' || validatedOTP === null}
          nextButtonRef={nextButtonRef}
          isLoading={isLoading}>
          Next
        </ButtonGreen>
        {/* <Buttons.BackNextButtons
          backOnClick={() => isCommercial ? ButtonDelay(navTo.commercial) : ButtonDelay(navTo.letsgetyouverified_3)}
          nextOnClick={() => isCommercial ? ButtonDelay(navTo.dashboard) : createUserAndNavigateNext()}
          nextDisabled={validatedOTP === 'failed' || validatedOTP === null}
          nextButtonRef={nextButtonRef}
          isLoading={isLoading}
        /> */}
      </Flex>
    )
  }

  const LetVerify = "Let’s get you verified"


  return (
    <Layout.ColumnLayout fitToHeightOnMobile={true} title={LetVerify}>
      <HeaderBanner />

      {/* mobile only */}
      <Vis.MobileOnly flex="col">
        <Flex flex="col">
          <BodyWrapper flex="col">
            <WhiteSection flex="col nogrow" style={{ margin: '29px 10px 0px 10px' }}>
              <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{LetVerify}</Text.H1>
              <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Please enter the 6-digit one-time password we’ve sent to <b>{mobile}</b></GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="col nogrow center" style={{ padding: '0px 17px' }}>
              <Flex flex="col" >
                <EnterOTPSection validatedOTP={validatedOTP} verifyToken={verifyToken} resendToken={resendToken} validateTextMsg={validateTextMsg} />
              </Flex>
            </WhiteSection>
          </BodyWrapper>
          <Footer flex="col nogrow">
            <WhiteSection flex="row nogrow" style={{ margin: '0px 0px 0px 0px' }}>
              <ButtonGroup />
            </WhiteSection>
          </Footer>
        </Flex>
      </Vis.MobileOnly>

      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{LetVerify}</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Please enter the 6-digit one-time password we’ve sent to <b>{mobile}</b></GreenBarSection>
        </WhiteSection>
        <LightBlueSection flex="col hcenter nogrow" style={{ margin: '0px 70px', padding: '50px 20px' }}>
          <Flex flex="col" style={{ width: '420px' }}>
            <EnterOTPSection validatedOTP={validatedOTP} verifyToken={verifyToken} resendToken={resendToken} validateTextMsg={validateTextMsg} />

            {/* fit to full page height on mobile */}
            <Flex />

            <Flex flex="row nogrow" style={{ marginTop: '25px', padding: '15px 0 80px 0px' }}>
              {/* <InfoSection /> */}
            </Flex>
          </Flex>
        </LightBlueSection>
        <Flex />
        <WhiteSection flex="row nogrow" style={{ margin: '40px 0px' }}>
          <ButtonGroup />
        </WhiteSection>
      </Vis.TabletOnly>

      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>{LetVerify}</Text.H1>
              <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial', maxWidth: '478px' }}>Please enter the 6-digit one-time password we’ve sent to <b>{mobile}</b></GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="row" style={{ marginTop: "82px", padding: "0px 0px", width: '424px' }}>
              <ButtonGroup />
            </WhiteSection>
          </WhiteSection>
          <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
            <LightBlueSection flex="col hcenter nogrow" style={{ padding: '50px 0 120px 0px' }}>
              <Flex flex="col" style={{ width: '400px' }}>
                <EnterOTPSection validatedOTP={validatedOTP} verifyToken={verifyToken} resendToken={resendToken} validateTextMsg={validateTextMsg} />

                {/* fit to full page height on mobile */}
                <Flex />

                <Flex flex="row nogrow" style={{ marginTop: '70px', padding: '10px' }}>
                  {/* <InfoSection /> */}
                </Flex>
              </Flex>
            </LightBlueSection>
          </Flex>

        </WhiteSection>
      </Vis.DesktopOnly>

    </Layout.ColumnLayout>
  )
}

export default LetsGetYouVerifiedOtpPage
